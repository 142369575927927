export const EXPO_PUBLIC_APP_VERSION = "6.181.0";
export const EXPO_PUBLIC_APP_NAME = "homegnome-customer-web";
export const EXPO_PUBLIC_BRAND_SLUG = "homegnome";
export const EXPO_PUBLIC_GOOGLE_API_KEY = "AIzaSyCtW4KAA_kr-LPbnH6EMPwOsnjvxqXTVuk";
export const EXPO_PUBLIC_CANCELLATION_SUPPORT_PHONE_NUMBER = "1-855-577-9573";
export const EXPO_PUBLIC_CANCELLATION_ZENDESK_WEB_CHANNEL_KEY = "b07f36f9-ca17-4d2c-a901-469903b468c5";
export const EXPO_PUBLIC_ZENDESK_WEB_CHANNEL_KEY = "a6a3a2c9-e915-4e3d-9df2-0654036dfc03";
export const EXPO_PUBLIC_SHARE_ON_NEXTDOOR_TIMEOUT_MINUTES = "5";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_3_CUT_MIN = "https://support.homegnome.com/hc/en-us/articles/24464433346066--Customer-Customer-Policy-FAQs";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H = "https://support.homegnome.com/hc/en-us/articles/24464433346066--Customer-Customer-Policy-FAQs";
export const SENTRY_ENVIRONMENT = "orange";
export const SENTRY_PROJECT_SLUG = "https://7a64fe4d24c140af9099be12c5818127@sentry.io/1367049";
export const EXPO_PUBLIC_ENV = "orange";
export const EXPO_PUBLIC_CUST_WEB_BASE_URL = "https://orange-my.homegnome.com";
export const EXPO_PUBLIC_PARTNERS_BASE_URL = "https://orange-partners-api.lawnstarter.com";
export const EXPO_PUBLIC_API_GRAPHQL_ENDPOINT = "https://orange-api.homegnome.com/graphql";
export const API_BASE_URL = "https://orange-api.homegnome.com";
export const SIGNUP_BASE_URL = "https://orange-signup-web.homegnome.com";
export const SEGMENT_KEY = "UEPOGKT3vwrm35cFMbPccd1CBdTiMDHX";
export const STRIPE_KEY = "pk_test_Gwo1qUvFZUQrufioqsESkESd";
export const LEGACY_CUST_WEB_BASE_URL = "https://orange-legacy-my.homegnome.com";
export const FLAGSMITH_ENVIRONMENT_KEY = "jzEweWzucx28tn92aiNAgB";